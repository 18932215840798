/**
 * Typography
 */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
	box-sizing: border-box;
}

html {
	overflow: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
	font-size: 10px;
}

body {
	overflow: hidden;
	font-size: var(--font-size-b2);
	;
	line-height: var(--line-height-b2);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
	color: var(--color-body);
	font-weight: var(--p-regular);
	background-color: var(--color-dark);
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
	margin: 0;
	margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	word-break: break-word;
	font-family: var(--secondary-font);
	line-height: 1.4074;
	color: var(--color-heading);
}

h1,
.h1 {
	font-size: var(--h1);
	line-height: 1.19;
}

h2,
.h2 {
	font-size: var(--h2);
	line-height: 1.23;
}

h3,
.h3 {
	font-size: var(--h3);
	line-height: 1.4;
}

h4,
.h4 {
	font-size: var(--h4);
	line-height: 1.25;
}

h5,
.h5 {
	font-size: var(--h5);
	line-height: 1.24;
}

h6,
.h6 {
	font-size: var(--h6);
	line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	a {
		color: inherit;
		transition: 0.5s;
	}
}

a {
	color: var(--color-body);
}

@media #{$md-layout} {

	h1,
	.h1 {
		font-size: 38px;
	}

	h2,
	.h2 {
		font-size: 32px;
	}

	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 24px;
	}

	h5,
	.h5 {
		font-size: 18px;
	}
}


@media #{$sm-layout} {

	h1,
	.h1 {
		font-size: 34px;
	}

	h2,
	.h2 {
		font-size: 28px;
	}

	h3,
	.h3 {
		font-size: 24px;
	}

	h4,
	.h4 {
		font-size: 20px;
	}

	h5,
	.h5 {
		font-size: 16px;
	}

}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
	font-weight: var(--p-bold);
}

h6,
.h6 {
	font-weight: var(--p-medium);
}

p {
	font-size: var(--font-size-b2);
	;
	line-height: var(--line-height-b2);
	font-weight: var(--p-regular);
	color: var(--color-body);
	margin: 0 0 30px;

	@media #{$sm-layout} {
		margin: 0 0 20px;
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.has-large-font-size {
		line-height: 1.5;
		font-size: 36px;
	}

	&.has-medium-font-size {
		font-size: 24px;
		line-height: 36px;
	}

	&.has-small-font-size {
		font-size: 13px;
	}

	&.has-very-light-gray-color {
		color: var(--color-white);
	}

	&.has-background {
		padding: 20px 30px;
	}

	&.b1 {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
	}

	&.b2 {
		font-size: var(--font-size-b2);
		line-height: var(--line-height-b2);
	}

	&.b3 {
		font-size: var(--font-size-b3);
		line-height: var(--line-height-b3);
	}

	&.b4 {
		font-size: var(--font-size-b4);
		line-height: var(--line-height-b4);
	}


}

.b1 {
	font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
}

.b2 {
	font-size: var(--font-size-b2);
	line-height: var(--line-height-b2);
}

.b3 {
	font-size: var(--font-size-b3);
	line-height: var(--line-height-b3);
}

.b4 {
	font-size: var(--font-size-b4);
	line-height: var(--line-height-b4);
}


table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0 0 40px;
	width: 100%;
}

table a,
table a:link,
table a:visited {
	text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
	color: var(--color-heading);
}


var {
	font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
	padding-left: 18px;
}

ul {
	list-style: square;
	margin-bottom: 30px;
	padding-left: 20px;

	&.liststyle {
		&.bullet {
			li {
				font-size: 18px;
				line-height: 30px;
				color: var(--color-body);
				position: relative;
				padding-left: 30px;

				@media #{$sm-layout} {
					padding-left: 19px;
				}

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background: var(--color-body);
					left: 0;
					top: 10px;
				}

				&+li {
					margin-top: 8px;
				}
			}
		}
	}

	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		margin-top: 10px;
		margin-bottom: 10px;
		color: var(--color-body);
		font-weight: 400;

		a {
			text-decoration: none;
			color: var(--color-body);
			@extend %transition;

			&:hover {
				color: var(--color-primary);
			}
		}
	}

	ul {
		margin-bottom: 0;
	}
}

ol {
	margin-bottom: 30px;

	li {
		font-size: var(--font-size-b1);
		line-height: var(--line-height-b1);
		color: var(--color-gray);
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 400;

		a {
			color: var(--heading-color);
			@extend %transition;
			text-decoration: none;

			&:hover {
				color: var(--color-primary);
			}
		}
	}

	ul {
		padding-left: 30px;
	}
}

hr {
	display: block;
	height: 0;
	margin: 15px 0 15px;
	border-top: 1px solid var(--color-border);
}

kbd {
	background: var(--color-primary);
}

.text-center {
	text-align: center !important;
	max-width: 750px;
	margin: 20rem;



}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
	.text-center {
		text-align: left;
		max-width: 700px;
		margin: 0 auto;
	}
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 612px) {
	.text-center {
		text-align: center !important;
		max-width: 700px;
		margin-left: 10rem;
	}
}

@media screen and (max-width: 462px) {
	.text-center {
		text-align: center !important;
		max-width: 700px;
		margin-right: 5rem;
	}
}